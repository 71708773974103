<script context="module">
  import { getBranchData } from "./modules/Branch.svelte";
  import { apiPostJSON } from "utils/api";
  import { showToast } from "utils/toast";

  let submitting = false;
  let isError = false;
  let branchData = null;

  export async function initCachedBranch() {
    try {
      branchData = await getBranchData();
    } catch (e) {
      branchData = null;
      console.log("Branch retrieval failed: ", e);
    }
  }

  export async function onSubmitContactRequest({
    email,
    firstName,
    lastName,
    fullName,
    message,
    redirectTo,
    source,
    token,
  } = {}) {
    if (submitting) {
      return false;
    }
    const data = {
      email,
      message,
      source,
      first_name: firstName,
      last_name: lastName,
      full_name: fullName,
      referral_payload: branchData,
      hostname: window.location?.hostname,
      token,
    };
    submitting = true;
    const response = await apiPostJSON("/contact/request/", data);
    submitting = false;
    isError = response.status === "error";
    if (!isError && redirectTo) {
      window.location.href = redirectTo;
      return true;
    }
    if (response.message) {
      showToast(response.message, isError);
    }
    return !isError;
  }
</script>
