<script>
  import Link from "components/elements/Link.svelte";

  export let dark = false;
  export let green = false;
  export let purple = false;
  export let gold = false;
  export let gold_flat = false;
  export let bdr_white = false;
  export let bdr_grey = false;
  export let bdr_green = false;
  export let bdr_dark = false;
  export let transparent = false;
  export let wide = false;
  export let twitter = false;
  export let facebook = false;
  export let style = "";
  export let buttonType;
  export let padded = false;
  export let linkClass = "";
  export let fullWidth = false;
  export let reCAPTCHA = false;
  export let dataCallback = undefined;

  export let href = "";

  // reCAPTCHA v3 site key
  const reCAPTCHA_site_key = "6Le15egqAAAAAKfm5vMqCCchXab6J9At-WmB8HYa";

  export let onClick = () => {
    if (href) {
      window.location = href;
    }
  };

  window.onDataCallback = (token) => {
    dataCallback(token);
  };
</script>

<Link dark scrollTop {href} class={linkClass}>
  <button
    type={buttonType}
    data-sitekey={reCAPTCHA ? reCAPTCHA_site_key : ""}
    data-callback="onDataCallback"
    data-action={reCAPTCHA ? "submit" : ""}
    on:click={onClick}
    class=" btn rounded{gold ? ' gold' : ''}{gold_flat
      ? ' gold_flat'
      : ''}{green ? ' green' : ''}{purple ? ' purple' : ''}{dark
      ? ' dark'
      : ''}{bdr_white ? ' bdr_white' : ''}{bdr_grey
      ? ' bdr_grey'
      : ''}{bdr_green ? ' bdr_green' : ''}{bdr_dark
      ? ' bdr_dark'
      : ''}{transparent ? ' transparent' : ''}{wide ? ' wide' : ''}{fullWidth
      ? ' full_width'
      : ''}{twitter ? ' twitter' : ''}{facebook ? ' facebook' : ''}{padded
      ? ' bdr_dark'
      : ''}{$$props.class ? ` ${$$props.class}` : ''}"
    {style}
  >
    <slot />
  </button>
</Link>

<style lang="scss">
  @import "../../styles/global.scss";

  .btn {
    padding: 8px 16px;
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    &:hover {
      filter: brightness(110%);
      cursor: pointer;
    }
    &.shadow {
      box-shadow: 0px 2px 9px 0px rgba(27, 29, 48, 0.81);
    }
    &.transparent {
      background: transparent;
      background-color: transparent;
      color: $gold;
    }
    &.twitter {
      background: #1da1f2;
    }
    &.facebook {
      background: #3b5998;
    }
    &.gold {
      background: #d9b44f;
      color: white;
      border-radius: 4px;
      padding: 6px 16px;
    }
    &.gold_flat {
      color: $white;
      border-radius: 6px;
      border: 1px solid var(--color-G40);
      background: var(--color-G40);
    }
    &.green {
      color: $white;
      border-radius: 6px;
      border: 1px solid var(--color-P60);
      background: var(--color-P60);
    }
    &.purple {
      background: linear-gradient(
        50deg,
        #211f2c 0%,
        #392854 27.6%,
        #432e5c 67.19%,
        #8b70af 100%
      );
      color: $white !important;
    }
    &.bdr_white {
      border: solid 1px var(--color-N10);
      color: var(--color-N10);

      background: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);

      &:hover {
        filter: brightness(100%);
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
      &:active {
        background: rgba(235, 237, 238, 0.5);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
    }
    &.bdr_grey {
      border-radius: 6px;
      border: 1px solid var(--color-N30);
      color: var(--color-N80);

      background: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);

      &:hover {
        filter: brightness(100%);
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
      &:active {
        background: rgba(235, 237, 238, 0.5);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
    }
    &.bdr_green {
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 6px;
      border: 1px solid var(--color-P35);
      color: var(--color-P75);
    }
    &.bdr_dark {
      color: $dark;
      border: solid 1px $dark;
      background: #ffffff01;
    }
    &.wide {
      min-width: 180px;
      font-size: 18px;
    }
    &.full_width {
      width: 100%;
    }
  }
</style>
