export const trackEvent = (eventName = "PageView", params = {}) => {
  const { page, path, wild } = params ?? {};
  const eventParams = {
    ...params,
    content_name: wild || path || page,
  };

  if (!window.fbq) {
    console.log(
      "fbq is not installed, skipping event: ",
      eventName,
      eventParams
    );
    return;
  }

  window.fbq("track", eventName, eventParams);
  console.log(`Logged event: ${eventName}`, eventParams);
};
