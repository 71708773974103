<script>
  import PageLayout from "components/PageLayout.svelte";
  import ContactForm from "./Sections/ContactForm.svelte";
  import { onMount } from "svelte";
  import { trackEvent } from "utils/analytics";

  onMount(() => {
    trackEvent("PageView", {
      page: "Contact",
    });
  });
</script>

<PageLayout blackHeader>
  <ContactForm />
</PageLayout>
