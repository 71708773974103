<script>
  import { onMount } from "svelte";
  import Spinner from "components/elements/Spinner.svelte";
  import PageLayout from "components/PageLayout.svelte";
  import { trackEvent } from "utils/analytics";

  onMount(() => {
    trackEvent("PageView", {
      page: "Blog",
    });
  });
</script>

<PageLayout blackHeader>
  <div class="loading-block"></div>
  <div id="dib-posts" class="blog-content"></div>
  <script
    async
    src="https://io.dropinblog.com/embedjs/3627b920-4c48-4974-9cc4-40a0c41b7f8c.js"
  ></script>
  <div id="dib-posts"></div>
</PageLayout>

<style lang="scss">
  @import "../../../styles/global.scss";
  .blog-content {
    position: relative;
    top: 92px;
    margin-bottom: 184px;
    @media only screen and (max-width: $size-phone-small) {
      top: 76px;
    }
  }
  .loading-block {
    @extend .generic-section;

    row-gap: 0px;
    background-color: $white;
    min-height: calc(100vh - 429px);
    padding: 68px 0px 64px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1px;

    @media only screen and (max-width: $size-tablet-mid) {
      min-height: calc(100vh - 64px);
    }
    @media only screen and (max-width: $size-phablet-max) {
      min-height: 100%;
      padding: 68px 0px 64px;
    }
  }
</style>
