<script>
  import { Router, Route } from "svelte-routing";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import {
    PRODUCTION_HOSTNAMES,
    REGISTRATION_HOSTNAMES,
    REGISTRATION_URL,
    isReferralPortal,
    isDynamicReferralPage,
  } from "./utils/menu";
  import { initBranch } from "./modules/Branch.svelte";
  import Landing from "./components/pages/Landing/Landing.svelte";
  // import Register from "./components/pages/Register/Register.svelte";
  import Legal from "./components/pages/Documents/Legal.svelte";
  import GenericDocs from "./components/pages/Documents/Generic.svelte";
  import Contacts from "./components/pages/Contacts/Contacts.svelte";
  import Blog from "./components/pages/Blog/Blog.svelte";
  import RegisterLogin from "./components/pages/Login/Login.svelte";
  import Referral from "./components/pages/Referral/Referral.svelte";
  import ThankYou from "./components/pages/ThankYou/ThankYou.svelte";
  import AffiliateLanding from "./components/pages/AffiliateLanding/AffiliateLanding.svelte";
  import APIReference from "./components/pages/API/Reference.svelte";

  const isRegistrationPage = REGISTRATION_HOSTNAMES.includes(
    window.location?.hostname
  );
  // Only render GA tags in production
  const renderAnalytics =
    isProduction &&
    (PRODUCTION_HOSTNAMES.includes(window.location?.hostname) ||
      isRegistrationPage);

  const isReferral = isReferralPortal();
  const isDynamicReferral = isDynamicReferralPage();

  if (isRegistrationPage && !isDynamicReferral && !isDynamicReferral) {
    window.location.href = REGISTRATION_URL;
  }

  // Svelte toast options
  const options = {
    theme: {
      "--toastMsgPadding": "20px 20px",
    },
  };

  export let url = "";

  initBranch();
</script>

<SvelteToast {options} />
<Router {url}>
  <Route path="/api-reference"><APIReference /></Route>

  <div class="root">
    {#if renderAnalytics}
      <script>
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-NS6NKP53");
      </script>

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-2NWTM6KPJD"
      ></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());

        gtag("config", "G-2NWTM6KPJD");
      </script>

      <noscript
        ><iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NS6NKP53"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe></noscript
      >

      <!-- Meta Pixel Code -->
      <script>
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "2399929130387488");
      </script>
      <noscript
        ><img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=2399929130387488&ev=PageView&noscript=1"
        /></noscript
      >
      <!-- End Meta Pixel Code -->
    {/if}
    <section>
      <div class="content">
        <Route path="/legal/*"><Legal /></Route>
        <Route path="/docs/*"><GenericDocs /></Route>
        <Route path="/blog"><Blog /></Route>
        <Route path="/register"><RegisterLogin /></Route>
        <Route path="/thank-you"><ThankYou /></Route>
        <Route path="/affiliate"><AffiliateLanding /></Route>
        <Route path="/auth"><RegisterLogin /></Route>
        <Route path="/contact-us"><Contacts /></Route>

        {#if isDynamicReferral && !isReferral}
          <Route path="/"><AffiliateLanding /></Route>
          <Route path="*"><AffiliateLanding /></Route>
        {/if}
        {#if isReferral}
          <Route path="/"><Referral /></Route>
          <Route path="*"><Referral /></Route>
        {/if}
        {#if !isDynamicReferral && !isReferral}
          <Route path="/"><Landing /></Route>
          <Route path="*"><Landing /></Route>
        {/if}
      </div>
    </section>
  </div>
</Router>

<style lang="scss">
  @import "./styles/global.scss";

  .root {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    font-family: "Heebo", "sans-serif";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    background: var(--color-bg-white);
    > section > div.content {
      display: flex;
      flex-direction: column;
    }
  }
</style>
