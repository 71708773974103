<script>
  import PageLayout from "components/PageLayout.svelte";
  import LoginForm from "./Sections/LoginForm.svelte";
  import { onMount } from "svelte";
  import { trackEvent } from "utils/analytics";

  onMount(() => {
    trackEvent("PageView", {
      page: "Auth",
    });
  });
</script>

<PageLayout blackHeader>
  <LoginForm />
</PageLayout>
