<script>
  import { onMount } from "svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import {
    initCachedBranch,
    onSubmitContactRequest,
  } from "./modules/Contact.svelte";
  import Spinner from "components/elements/Spinner.svelte";
  import Btn from "components/elements/Btn.svelte";

  let fullName = "";
  let email = "";

  export let affiliateTitle = "";
  export let affiliateSubtitle = "";
  export let buttonText = "";
  export let disclaimerShort = "";
  export let desktopImageSrc = "";
  export let desktopImageAlt = "";
  export let desktopImageStyle = "";
  export let mobileImageSrc = "";
  export let mobileImageAlt = "";
  export let mobileImageStyle = "";

  $: {
    if (!affiliateTitle) {
      affiliateTitle =
        "Learn How Digital Gold Can Help Preserve Your Wealth With Our FREE Investor Guide";
    }
    if (!desktopImageSrc) {
      desktopImageSrc = "/images/magazines_opened.webp";
    }
    if (!mobileImageSrc) {
      mobileImageSrc = "/images/magazines_opened.webp";
    }
    if (!desktopImageAlt) {
      desktopImageAlt =
        "Liquid Noble guide on how to invest in gold with NobleGold and NobleMetals, showcasing the benefits of digital gold investments.";
    }
    if (!mobileImageAlt) {
      mobileImageAlt =
        "Liquid Noble guide on how to invest in gold with NobleGold and NobleMetals, showcasing the benefits of digital gold investments.";
    }
  }

  async function handleSubmit(token) {
    onSubmitContactRequest({
      email,
      fullName,
      message: "--- Affiliate referral ---",
      redirectTo: "/thank-you",
      token,
    });
  }

  onMount(() => {
    initCachedBranch();
  });
</script>

{#if !buttonText}
  <div class="spinner-wrapper">
    <Spinner />
  </div>
{:else}
  <PageSection no_padding white>
    <div class="affiliate-hero">
      <div class="columns">
        <div class="column text-column">
          <div class="affiliate-header">
            <span>
              {@html affiliateTitle}
            </span>
          </div>
          <div class="subtitle">
            {@html affiliateSubtitle}
          </div>
          <form>
            <div class="buttons">
              <div class="button-row">
                <input
                  class="hero-input"
                  type="text"
                  placeholder="Name"
                  bind:value={fullName}
                  required
                />
                <input
                  class="hero-input"
                  type="email"
                  placeholder="Email"
                  bind:value={email}
                  required
                />
              </div>
            </div>
            <div class="cta-button-row">
              <div class="cta-button">
                <Btn
                  gold
                  reCAPTCHA
                  dataCallback={handleSubmit}
                  wide
                  fullWidth
                  buttonType="submit">{buttonText}</Btn
                >
                <div></div>
              </div>
            </div>
            {#if disclaimerShort}
              <div class="header-disclaimer">
                {@html disclaimerShort}
              </div>
            {/if}
          </form>
          <div class="mobile">
            <img
              style={mobileImageStyle}
              src={mobileImageSrc}
              class="dashboard-image"
              alt={mobileImageAlt}
            />
          </div>
        </div>
        <div class="column image-column desktop">
          <img
            style={desktopImageStyle}
            src={desktopImageSrc}
            class="dashboard-image"
            alt={desktopImageAlt}
          />
        </div>
      </div>
    </div>
  </PageSection>
{/if}

<style lang="scss">
  @import "../../../../styles/global.scss";

  .spinner-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .affiliate-hero {
    @extend .generic-section;
    width: 100%;
    max-width: $size-laptop-max;
    gap: 128px;
    .columns {
      position: relative;
      justify-content: space-between;
      column-gap: 52px;
      margin: 0px;
      margin-top: 46px;
      padding: 128px 48px 0px 0px;
      width: 100%;
      max-width: 100%;
      height: 100%;
      background-color: #ffffff;

      @media only screen and (max-width: $size-desktop-min) {
        padding-left: 36px;
      }
    }
    .highlighted-text {
      color: #d9b44f;
    }
    .affiliate-header {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media only screen and (max-width: $size-tablet-max) {
        font-size: 32px;
        line-height: 55px;
      }
      @media only screen and (max-width: $size-phone-max) {
        font-size: 32px;
        line-height: normal;
      }
      @media only screen and (max-width: $size-phone-small) {
        font-size: 24px;
        line-height: normal;
      }
    }
    .subtitle {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      max-width: 537px;

      @media only screen and (max-width: $size-tablet-max) {
        font-size: 18px;
        line-height: normal;
      }
      @media only screen and (max-width: $size-phone-max) {
        font-size: 16px;
        line-height: normal;
      }
    }
    .column {
      row-gap: 12px;
      z-index: 1;
      &.text-column {
        width: 664px;
        max-width: 664px;
        margin-left: 48px;

        @media only screen and (max-width: $size-phablet-min) {
          margin-left: 0px;
        }

        @media only screen and (max-width: $size-phablet-min) {
          padding-top: 0px;
        }
      }
      &.image-column {
        padding-top: 0px;
        width: 534px;
        height: fit-content;
      }
      .dashboard-image {
        height: 640px;
        width: fit-content;
      }
      .mobile {
        display: none;
        width: 100%;
        .dashboard-image {
          width: 100%;
          height: auto;
        }
      }
      .image {
        width: 314px;
      }
    }
    .buttons {
      width: 100%;
      padding-bottom: 24px;
      max-width: 585px;
      overflow: visible;
      flex-wrap: nowrap;

      @media only screen and (max-width: $size-tablet-max) {
        justify-content: center;
      }

      span {
        display: flex;
        gap: 12px;
      }
      .hero-input {
        width: 180px;
        height: 36px;
        border-radius: 4px;
        background: var(--N10, #f4f4f6);
        font-family: Heebo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        padding: 3px 10px;
        border: none;
        &::placeholder {
          color: var(--color-N50);
        }

        @media only screen and (max-width: $size-phone-max) {
          width: 100%;
        }
      }
    }
    .button-row {
      display: flex;
      min-width: 214px;
      column-gap: 24px;

      @media only screen and (max-width: $size-phablet-min) {
        flex-direction: column;
        gap: 12px;
      }
    }
    .hero-input + .hero-input {
      @media only screen and (max-width: $size-phone-max) {
        width: 100%;
      }
    }
    .cta-button-row {
      width: 100%;
      display: flex;
      margin-bottom: 32px;
      @media only screen and (max-width: $size-tablet-max) {
        justify-content: center;
      }

      .cta-button {
        width: 100%;
        max-width: 384px;
        @media only screen and (max-width: $size-phone-max) {
          max-width: 214px;
        }
      }
    }
    .header-disclaimer {
      color: #989999;
      font-size: 8px;
      font-weight: 400;
      line-height: 10px;
    }
    @media only screen and (min-width: $size-phablet-min) and (max-width: $size-tablet-max) {
      .columns {
        text-align: center;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .mobile {
          display: flex;
        }
        .desktop {
          display: none;
        }
        .image {
          margin-top: 48px;
          width: 260px;
        }
      }
    }
    @media only screen and (max-width: $size-phablet-min) {
      gap: 80px;

      .columns {
        flex-direction: column-reverse;
        text-align: center;
        .column {
          width: 100%;
          gap: 24px;
          &.desktop {
            display: none;
          }
          .image {
            margin-top: 48px;
            width: 180px;
          }
          .mobile {
            display: flex;
            padding-bottom: 46px;
          }
        }
        .buttons {
          gap: 12px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
</style>
