<script>
  import PageLayout from "components/PageLayout.svelte";
  import { onMount } from "svelte";
  import { trackEvent } from "utils/analytics";

  let redocLoaded = false;

  onMount(() => {
    trackEvent("PageView", {
      page: "API",
    });

    const loadRedocScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "/redoc.standalone-2.0.0-rc.66.js";
        script.async = true;
        script.onload = () => resolve(true);
        script.onerror = () => reject(new Error("Failed to load Redoc script"));
        document.body.appendChild(script);
      });
    };

    loadRedocScript()
      .then(() => {
        redocLoaded = true;
        Redoc.init(
          "/LQN.openapi.yaml",
          {
            theme: {
              rightPanel: {
                backgroundColor: "#322569",
              },
              typography: {
                fontSize: "14px",
                fontFamily:
                  '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              },
            },
          },
          document.getElementById("redoc-container")
        );
      })
      .catch((error) => {
        console.error("Error loading Redoc script:", error);
      });
  });
</script>

<PageLayout blackHeader footerClass="api-reference-footer">
  <div class="api-reference-container">
    <div id="redoc-container"></div>
  </div>
</PageLayout>

<style lang="scss">
  :root {
    --link-text-color: #1668dc;
    --bg-overlay: #f2f2f2;
  }

  :root.light {
    --sidebar-active-background-color: var(--color-purple-2);
  }

  :root.dark {
    --link-text-color: orange;
  }
  :global(.api-reference-footer) {
    margin-top: 92px;
  }
  .api-reference-container {
    all: initial;
    position: relative;
    top: 92px;

    :global(.scrollbar-container a),
    :global(.redoc-markdown a) {
      font-size: unset;
    }
    :global(.scrollbar-container label:hover) {
      color: #000000;
      font-weight: bold;
    }
    :global(.api-content a) {
      border: 0px;
      font-size: unset;
    }
    :global(.redoc-wrap > div:nth-child(4)) {
      // background: var(--color-bg-purple);
    }

    .menu-content > div > img {
      padding: 16px 16px;
      width: 184px;
    }

    /* Search bar */
    div[role="search"] {
      margin: 0 0 12px;
    }
    div[role="search"] input {
      margin: 0 12px;
      border: 1px solid #e5e8ec;
      border-radius: 6px;
      padding: 6px 10px 6px 32px;
      background-color: white;
      width: calc(100% - 24px);
      font-weight: 400;
    }
    div[role="search"] svg {
      left: 24px;
      height: 32px;
    }
    div[role="search"] svg path {
      fill: #69768c;
    }
    ::placeholder {
      color: #69768c;
      opacity: 1;
    }
    div[role="search"] i {
      line-height: 32px;
      color: #69768c;
      width: 12x;
    }

    /* Search results */
    div[data-role="search:results"] {
      margin-top: 8px;
      padding: 8px 0;
      background-color: #f1f2f4;
    }
    div[data-role="search:results"] label[role="menuitem"]:hover {
      background-color: #e5e8ec;
    }
    div[data-role="search:results"] span:not(.operation-type) {
      color: #20252d;
    }
    div[data-role="search:results"]
      label[role="menuitem"]:hover
      span:not(.operation-type) {
      color: #20252d;
    }

    /*
      Menu panel styles
      */

    .menu-content {
      background-color: #f8f9fa;
    }
    .menu-content ul[role="navigation"] > li > label,
    .menu-content
      ul[role="navigation"]
      > li[data-item-id*="Active"]
      > ul
      > li
      > label,
    .menu-content
      ul[role="navigation"]
      > li[data-item-id*="Disconnected"]
      > ul
      > li
      > label {
      font-size: 14px;
      font-weight: 600;
      color: #20252d;
    }
    .menu-content ul[role="navigation"] li > label > svg > polygon {
      fill: #69768c;
    }
    .menu-content ul[role="navigation"] > li > ul label {
      font-size: 14px;
      color: #20252d;
    }
    .menu-content ul[role="navigation"] > li label.active,
    .menu-content ul[role="navigation"] > li label.active:hover {
      background-color: #e5e8ec;
    }
    .menu-content li label:hover {
      background-color: #f1f2f4;
    }
    .menu-content li[data-item-id*="Active"] > label,
    .menu-content li[data-item-id*="Disconnected"] > label {
      padding-bottom: 4px;
    }
    .menu-content li[data-item-id*="Active"] > label > span,
    .menu-content li[data-item-id*="Disconnected"] > label > span {
      color: #3f4959;
    }

    /* HTTP method lozenges */
    span.operation-type {
      margin-top: 3px;
      margin-right: 12px;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 16px;
      height: 16px;
      width: 34px;
    }
    span.operation-type.get {
      background-color: #03875d;
    }
    span.operation-type.post {
      background-color: #2a64bb;
    }
    span.operation-type.put {
      background-color: #964278;
    }
    span.operation-type.delete {
      background-color: #ca212f;
    }

    /*
      Middle panel styles
      */

    div.api-content p {
      color: #20252d;
    }

    div.api-content h1 {
      font-weight: 600;
      color: #20252d;
    }

    div.api-content h2 {
      margin-top: 0;
    }

    div.api-content h5 {
      border-bottom-color: #e5e8ec;
      color: #69768c;
      font-size: 14px;
    }

    div.api-content h5 span {
      color: #3f4959;
    }

    div.api-content a {
      color: #2a64bb;
    }
    div.api-content a:hover {
      color: #234986;
    }

    div.api-content code,
    div[id*="operation"] td li span {
      color: #20252d;
      background-color: #f8f9fa;
      border-color: #e5e8ec;
      padding: 0 3px;
      font-family: "Source Code Pro", monospace !important;
      font-weight: 500;
    }
    div.api-content .redoc-json code {
      color: white;
      background-color: transparent;
    }

    /* Schema download button */
    .api-info > h1 + p > a {
      border: 0;
      padding: 0;
    }

    /* Intro section */
    div.api-content > div:first-child {
      padding: 20px 0 0 0;
    }

    /* Authentication format table */
    .security-details {
      display: none !important;
    }

    /* Section divider line */
    div[id*="operation"]:not(:last-of-type)::after {
      border-bottom-color: #e5e8ec;
    }

    /* Child parameter container */
    div[id*="operation"] tr.expanded + tr > td > div {
      background-color: #f8f9fa;
      border-radius: 6px;
      padding: 16px;
    }

    /* Parameter divider lines */
    div[id*="operation"] tr > td {
      border-bottom-color: #e5e8ec;
    }
    div[id*="operation"] tr.last > td:last-child {
      border-bottom-width: 0;
    }

    /* Hide parameter tree */
    div[id*="operation"] tr > td:first-child {
      border-left-width: 0;
      background-image: none;
      padding-right: 16px;
    }
    div[id*="operation"] tr > td > span:first-child {
      margin-right: 0;
    }
    div[id*="operation"] tr > td > span:first-child::before {
      display: none;
    }
    div[id*="operation"] tr > td > span:first-child::after {
      display: none;
    }

    /* Parameter names */
    div[id*="operation"] tr > td:first-child span {
      font-family: "Source Code Pro", monospace !important;
      font-weight: 500;
    }

    /* Required parameter tag */
    div[id*="operation"] tr > td:first-child:not([colspan="2"]) > div {
      margin-left: 0;
      color: #ca212f;
    }

    /* Parameter type */
    div[id*="operation"] tr > td:last-child > div > div:first-child > span {
      color: #3f4959;
    }

    /* Parameter code examples */
    div[id*="operation"] > div > div:first-child tr > td:last-child li > span {
      font-family: "Source Code Pro", monospace !important;
    }

    /* Response code headers */
    div[id*="operation"] > div > div:first-child div:last-child > div > button {
      border-radius: 4px;
      outline: none;
    }

    /*
      Right panel styles
      */

    /* Example panel background */
    div[id*="operation"] > div > div + div {
      background-color: #f8f9fa !important;
    }
    .redoc-wrap > div + div + div + div {
      background-color: #f8f9fa !important;
    }

    /* Endpoint example */
    div[id^="operation"] > div > div:last-child > div {
      margin-top: 16px;
    }

    /* Endpoint example method lozenge */
    div[id^="operation"] > div > div:last-child > div > button {
      padding-left: 12px;
      border-radius: 6px;
      box-shadow: none;
      background-color: #333b48;
    }
    div[id^="operation"] button > span.http-verb {
      border-radius: 6px;
      font-weight: 600;
      letter-spacing: 0.2px;
      padding: 0 10px;
    }
    div[id^="operation"] button > span.get {
      background-color: #03875d;
    }
    div[id^="operation"] button > span.post {
      background-color: #2a64bb;
    }
    div[id^="operation"] button > span.put {
      background-color: #964278;
    }
    div[id^="operation"] button > span.delete {
      background-color: #ca212f;
    }

    /* Endpoint text */
    div[id^="operation"]
      > div
      > div:last-child
      > div
      > button
      > span:nth-child(2) {
      font-size: 12px;
      font-weight: 500;
      font-family: "Source Code Pro", monospace !important;
    }

    /*  Endpoint caret */
    div[id^="operation"] > div > div:last-child > div > button > svg {
      margin-right: -20px !important;
    }
    div[id^="operation"] > div > div:last-child > div > button > svg > polygon {
      fill: #98a2b4;
    }

    /* Full endpoint dropdown */
    div[id^="operation"] > div > div:last-child > div > div:last-child {
      border-radius: 6px;
      background-color: #f8f9fa;
    }
    div[id^="operation"]
      > div
      > div:last-child
      > div
      > div:last-child
      > div
      > div:last-child
      > div {
      border-color: #c2c8d2;
      color: #2a64bb;
    }
    div[id^="operation"]
      > div
      > div:last-child
      > div
      > div:last-child
      > div
      > div:last-child
      > div
      > span {
      color: #20252d;
    }

    /*
      Request and Response Samples
      */

    /* Labels for request & response samples */
    div[id^="operation"] > div > div:last-child h3 {
      color: #20252d;
      font-size: 16px;
      font-weight: 600;
      margin: 32px 0 10px;
    }

    /* Tab styles */
    div[id^="operation"] > div > div:last-child ul.react-tabs__tab-list {
      border-radius: 6px 6px 0 0;
      margin: 0;
      padding: 0 12px;
      height: 40px;
      background-color: #333b48;
    }
    div[id^="operation"] > div > div:last-child li[role="tab"] {
      border-radius: 0;
      border-width: 0;
      border-color: #2a64bb;
      margin: 0;
      padding: 12px 12px;
      color: #c2c8d2;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      height: 40px;
      min-width: 56px;
    }
    div[id^="operation"] > div > div:last-child li[role="tab"]:hover {
      color: white;
    }
    div[id^="operation"] > div > div:last-child li.react-tabs__tab--selected {
      color: white;
      border-width: 0 0 2px 0;
    }
    div[id^="operation"]
      > div
      > div:last-child
      li.react-tabs__tab--selected:focus {
      outline: none;
    }

    /* Success response tab */
    div[id^="operation"] > div > div:last-child li.tab-success {
      border-color: #03875d;
    }

    /* Error response tab */
    div[id^="operation"] > div > div:last-child li.tab-error {
      border-color: #ca212f;
    }

    /* Sample body */
    div[id^="operation"] > div > div:last-child .react-tabs__tab-panel {
      border-radius: 0 0 6px 6px;
      background-color: #20252d;
    }
    div[id^="operation"] > div > div:last-child .react-tabs__tab-panel > div {
      padding: 10px 20px 20px 20px !important;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      > div
      > div:nth-child(2) {
      margin-top: 0px;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      > div
      > div:nth-child(2)
      > div
      > p {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    /* Hide content type dropdown */
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      > div
      > div:first-child {
      display: none;
    }

    /* Hide label for example dropdown */
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      > div
      > div:last-child
      > div:first-child
      > span {
      display: none;
    }

    /* Example dropdown style */
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      .dropdown {
      border-radius: 0;
      margin: -10px -20px 0;
      padding: 12px 20px;
      background-color: transparent;
      border-bottom: 1px solid #333b48;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      span.dropdown-selector-value {
      font-size: 14px;
      font-weight: 600;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      div.dropdown-arrow {
      border-top-color: #c2c8d2;
    }

    /* Example dropdown selection style */
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      span.dropdown-selector-content {
      left: 8px;
      width: calc(100% - 16px);
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      div.dropdown-option {
      padding: 0.4em 12px;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      div.dropdown-option:hover {
      background-color: #f1f2f4;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      div.dropdown-option.selected {
      background-color: #e5e8ec;
    }

    /* Sample description */
    div[id^="operation"] > div > div:last-child .react-tabs__tab-panel div > p {
      margin-bottom: 12px;
      color: #fff;
    }

    /* Copy, expand and collapse button styles */
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      button:not(.collapser) {
      padding: 0;
      margin-left: 10px;
      color: #69768c;
      opacity: 1;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      button:not(.collapser):focus {
      background-color: transparent;
      color: #69768c;
      opacity: 1;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      button:not(.collapser):hover {
      background-color: transparent;
      color: white;
      opacity: 1;
    }
    div[id^="operation"]
      > div
      > div:last-child
      .react-tabs__tab-panel
      button:not(.collapser):active {
      background-color: transparent;
      color: #99c1fa;
      opacity: 1;
    }

    /* Sample code font and syntax highlighting */
    div[id^="operation"] > div > div:last-child code div,
    div[id^="operation"] > div > div:last-child code span {
      font-weight: 400;
      font-family: "Source Code Pro", monospace !important;
    }
    div[id^="operation"]
      > div
      > div:last-child
      ul.obj
      span.string:not(.property) {
      color: #7fe6bd;
    }
    div[id^="operation"]
      > div
      > div:last-child
      ul.obj
      span.number:not(.property) {
      color: #99c1fa;
    }
    div[id^="operation"]
      > div
      > div:last-child
      ul.obj
      span.boolean:not(.property) {
      color: #f77272;
    }
    div[id^="operation"]
      > div
      > div:last-child
      ul.obj
      span.keyword:not(.property) {
      color: #ea6ca6;
    }

    /* Concepts */
    div[id^="section/Concepts"] > div > .redoc-markdown > p {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    div[id^="section/Concepts"] > div > .redoc-markdown > p > strong {
      display: block;
      margin-top: 10px;
      margin-bottom: 2px;
    }
  }
</style>
